import axios from "axios";
import React, { Component } from "react";
import { api_credentials } from "../vars.js";

import { Send } from "@mui/icons-material";
import Button from "@mui/material/Button";
export default class StartRace extends Component {
  confirmData() {
    if (window.confirm("Confirm all data?")) {
      const body = {
        key: api_credentials.key,
        api_user: api_credentials.user,
      };
      axios
        .post(api_credentials.url + "/startevent", body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          window.alert(res.data.Data);
        });
    }
  }
  render() {
    return (
      <div>
        <Button
          id={1}
          variant="contained"
          endIcon={<Send />}
          onClick={this.confirmData}
        >
          Confirm all data
        </Button>
      </div>
    );
  }
}
