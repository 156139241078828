import React from "react";
import "./Styles/insertTeam.css";
import Pilot from "./Pilot";
import axios from "axios";
import { api_credentials } from "../vars.js";
export default function InsertTeam() {
  const body = {
    key: api_credentials.key,
    api_user: api_credentials.user,
  };
  const [pilots, setPilot] = React.useState(1);
  const [teamName, setTName] = React.useState("");
  const [teamAbbr, setTAbbr] = React.useState("");
  const [teamNumber, setTNumber] = React.useState("");
  const [devId, setDevId] = React.useState("229LUL2020000XXX");
  const [country, setCountry] = React.useState("Germany");
  const [carClass, setCarClass] = React.useState("");
  const [fb, setFb] = React.useState("https://www.facebook.com/ecograndprix");
  const [insta, setInsta] = React.useState(
    "https://www.instagram.com/ecograndprix"
  );
  const [yt, setYt] = React.useState("https://www.youtube.com/ecogp");
  const [teamMotivation, setTeamMotivation] =
    React.useState("Welcome to ecoGP!");

  const [pilotName, setPName] = React.useState("");
  const [pilotAbbr, setPAbbr] = React.useState("");
  const [pilotCard, setPCard] = React.useState("");
  const [mainPilot, setMainPilot] = React.useState(0);

  React.useEffect(() => {
    console.log(mainPilot);
  }, [mainPilot]);

  function addNewPilot(e) {
    if (pilotName && pilotAbbr && pilotCard) {
      if (Number(teamNumber) && Number(pilotCard)) {
        if (String(pilotCard).length >= 7) {
          setPilot(pilots + 1);
          body.team_number = teamNumber;
          body.pilot_nr = pilots;
          body.pilot_name = pilotName;
          body.pilot_abbr = pilotAbbr;
          body.pilot_card = pilotCard;
          body.mainPilot = mainPilot === 1 ? "true" : "false";
          axios
            .post(api_credentials.url + "/newpilot", body, {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              console.log(res);
              res.data.Data === "Success!"
                ? mainPilot === 1
                  ? setMainPilot(0)
                  : setMainPilot(0)
                : window.alert(res.data.Data);
              window.alert(res.data.Data);
            });
          setPName("");
          setPAbbr("");
        } else {
          window.alert("cardID is 7 chars length");
        }
      } else {
        window.alert("teamNr and cardid are numeric strings");
      }
    } else {
      setPilot(pilots + 1);
      mainPilot === 1 ? setMainPilot(0) : setMainPilot(0);
    }
  }
  function removePilot() {
    if (pilots > 1) {
      setPilot(pilots - 1);
    }
  }

  function addTeam() {
    if (teamName && teamNumber && teamAbbr) {
      body.team_name = teamName;
      body.team_number = teamNumber;
      body.team_abbr = teamAbbr;
      body.dev_id = devId;
      body.country = country;
      body.car_class = carClass;
      body.fb = fb;
      body.insta = insta;
      body.yt = yt;
      body.team_motivation = teamMotivation;
      axios
        .post(api_credentials.url + "/newteam", body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          window.alert(res.data.Data);
          console.log("new-team")
          setPilot(1)
        });
    } else {
      window.alert("empty fields");
    }
  }
  return (
    <div className="team-form">
      <div className="team-infos">
        <div className="team-detail">
          <label htmlFor="name" className="teamLabels">
            Team Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="TRON IT"
            className="teamInput"
            value={teamName}
            onChange={(e) => {
              setTName(e.target.value);
            }}
          ></input>{" "}
        </div>
        <div className="team-detail">
          <label htmlFor="abbr" className="teamLabels">
            Team Abbreviation
          </label>
          <input
            type="text"
            id="abbr"
            placeholder="TRN"
            className="teamInput"
            value={teamAbbr}
            onChange={(e) => {
              setTAbbr(e.target.value);
            }}
          ></input>
        </div>
        <div className="team-detail">
          <label htmlFor="nr" className="teamLabels">
            Team Number
          </label>
          <input
            type="text"
            id="nr"
            placeholder="01"
            className="teamInput"
            value={teamNumber}
            onChange={(e) => {
              setTNumber(e.target.value);
            }}
          ></input>
        </div>
        <div className="team-detail">
          <label htmlFor="DEVICE_id" className="teamLabels">
            Device ID
          </label>
          <input
            type="text"
            id="DEVICE_id"
            placeholder="229LUL20200000XX"
            className="teamInput"
            value={devId}
            onChange={(e) => {
              setDevId(e.target.value);
            }}
          ></input>
        </div>
        <div className="team-detail">
          <label htmlFor="country" className="teamLabels">
            Country
          </label>
          <input
            type="text"
            id="country"
            placeholder="Country"
            className="teamInput"
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          ></input>
        </div>
        <div className="team-detail">
          <label htmlFor="car-class" className="teamLabels">
            Car Class
          </label>
          <input
            type="text"
            id="car-class"
            placeholder="Car Class"
            className="teamInput"
            value={carClass}
            onChange={(e) => {
              setCarClass(e.target.value);
            }}
          ></input>
        </div>

        <div className="team-detail">
          <label htmlFor="media-fb" className="teamLabels">
            Facebook Link
          </label>
          <input
            type="text"
            id="media-fb"
            placeholder="Facebook Link"
            className="teamInput"
            value={fb}
            onChange={(e) => {
              setFb(e.target.value);
            }}
          ></input>
        </div>
        <div className="team-detail">
          <label htmlFor="media-insta" className="teamLabels">
            Instagram Link
          </label>
          <input
            type="text"
            id="media-insta"
            placeholder="Instagram Link"
            className="teamInput"
            value={insta}
            onChange={(e) => {
              setInsta(e.target.value);
            }}
          ></input>
        </div>
        <div className="team-detail">
          <label htmlFor="media-yt" className="teamLabels">
            YouTube Link
          </label>
          <input
            type="text"
            id="media-yt"
            placeholder="YouTube Link"
            className="teamInput"
            value={yt}
            onChange={(e) => {
              setYt(e.target.value);
            }}
          ></input>
        </div>
        <div className="team-detail team-motivation">
          <label htmlFor="team-motivation" className="teamLabels">
            Team Motivation
          </label>

          <textarea
            type="textarea"
            id="team-motivation"
            placeholder="Team Motivation"
            className="teamInput"
            value={teamMotivation}
            onChange={(e) => {
              setTeamMotivation(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
      <button className="addTeam" onClick={() => addTeam()}>
        Add team
      </button>
      <div className="addPilotContainer">
        <div className="buttonsContainer">
          <button className="removePilot" onClick={(e) => removePilot(e)}>
            Go back!
          </button>
          <button className="addPilot" onClick={(e) => addNewPilot(e)}>
            Add new Pilot!
          </button>
        </div>

        <Pilot
          number={pilots}
          name={pilotName}
          abbr={pilotAbbr}
          card={pilotCard}
          isMain={mainPilot}
          teamNumber={teamNumber}
          setName={setPName}
          setAbbr={setPAbbr}
          setCard={setPCard}
          setTNumber={setTNumber}
          setMain={setMainPilot}
        ></Pilot>
      </div>
    </div>
  );
}
