import { Fragment, useState } from "react";
import "./App.css";
// import Ping from './Components/Ping'; // test api
import InsertStartDate from "./Components/InsertStartDate";
import StartRace from "./Components/StartRace";
import InsertTeam from "./Components/InsertTeam";
import SelectFlag from "./Components/SelectFlag";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { api_credentials } from "./vars.js";
import { Send } from "@mui/icons-material";
import Button from "@mui/material/Button";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import FileCopyIcon from '@mui/icons-material/FileCopy';
function App() {
  let body = {
    key: api_credentials.key,
    api_user: api_credentials.user,
  };
  const [evName, setEvName] = useState("");
  const [ticker, setTicker] = useState("");

  const [nextDriver, setNextDriver] = useState({
    aID: 0,
    nID: 0,
    swapTime: "2022-07-02 16:51:39.856245",
    teamNumber: "01",
  });

  const handleUpdate = (e) => {
    let _if = parseInt(e.nativeEvent.srcElement.id);
    body.text = _if ? ticker : evName;
    body.if = _if;
    axios.post(api_credentials.url + "/set-texts", body).then((res) => {
      window.alert(res.data.Data + "\n" + res.data.Message);
      setEvName("");
      setTicker("");
    });
  };

  const handleNextDriver = () => {
    console.log(nextDriver);
    body.aID = nextDriver.aID;
    body.nID = nextDriver.nID;
    body.swapTime = nextDriver.swapTime;
    body.teamNumber = nextDriver.teamNumber;
    axios.post(api_credentials.url + "/set-next-driver", body).then((res) => {
      console.log(res.data);
    });
  };
  const handleClear = () => {
    if (window.confirm("Are you sure you want to delete all data ?")) {
      axios.post(api_credentials.url + "/clear-tabels", body).then((res) => {
        console.log(res.data);
        window.alert(res.data === null ? "Successful!" : res.data);
      });
    }
  };
  const exportHandle = () =>{
    if (window.confirm("Are you sure you want to export all data ?")) {
      axios.post(api_credentials.url + "/export-data", body).then((res) => {
        console.log(res.data.Message);
        window.alert(res.data.Data === null ? "Successful!" : res.data.Data);
      });
    }
  }
  return (
    <Fragment>
      <div className="main">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "row",
            width: "80%",
            alignSelf: "center",
          }}
        >
          <InsertStartDate className="start-date-form"></InsertStartDate>
          <SelectFlag />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <StartRace></StartRace>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                id="filled-basic"
                label="Event name"
                variant="filled"
                value={evName}
                onChange={(e) => setEvName(e.target.value)}
              />
              <Button
                id={0}
                variant="contained"
                endIcon={<Send />}
                style={{
                  width: "50%",
                  marginTop: 10,
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                endIcon={<DeleteSweepIcon />}
                onClick={handleClear}
                color={"error"}
              >
                Clear DB Tabels
              </Button>
             
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
               <Button
                variant="contained"
                endIcon={<FileCopyIcon />}
                onClick={exportHandle}
                color={"success"}
              >
                Positions to CSV
              </Button>
             
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                alignSelf: "flex-end",
                justifySelf: "flex-end",
              }}
            >
              <TextField
                id="filled-basic"
                label="Ticker text"
                variant="filled"
                value={ticker}
                onChange={(e) => setTicker(e.target.value)}
              />
              <Button
                id={1}
                variant="contained"
                endIcon={<Send />}
                style={{
                  width: "50%",
                  marginTop: 10,
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 30,
              width: "30%",
            }}
          >
            <TextField
              fullWidth
              d="filled-basic"
              label="Actual Driver ID"
              variant="filled"
              value={nextDriver.aID}
              onChange={(e) =>
                setNextDriver((prevValues) => {
                  const newValues = { ...prevValues };
                  newValues.aID = e.target.value;
                  return newValues;
                })
              }
            />

            <TextField
              fullWidth
              d="filled-basic"
              label="Next Driver ID"
              variant="filled"
              value={nextDriver.nID}
              onChange={(e) =>
                setNextDriver((prevValues) => {
                  const newValues = { ...prevValues };
                  newValues.nID = e.target.value;
                  return newValues;
                })
              }
            />

            <TextField
              fullWidth
              d="filled-basic"
              label="Swap Time"
              variant="filled"
              value={nextDriver.swapTime}
              onChange={(e) =>
                setNextDriver((prevValues) => {
                  const newValues = { ...prevValues };
                  newValues.swapTime = e.target.value;
                  return newValues;
                })
              }
            />

            <TextField
              fullWidth
              d="filled-basic"
              label="Team Number"
              variant="filled"
              value={nextDriver.teamNumber}
              onChange={(e) =>
                setNextDriver((prevValues) => {
                  const newValues = { ...prevValues };
                  newValues.teamNumber = e.target.value;
                  return newValues;
                })
              }
            />
            <Button
              id={0}
              variant="contained"
              endIcon={<Send />}
              style={{
                width: "80%",
                marginTop: 10,
              }}
              onClick={handleNextDriver}
            >
              Update next driver
            </Button>
          </div>
        </div>
        <InsertTeam></InsertTeam>
      </div>
    </Fragment>
  );
}

export default App;
