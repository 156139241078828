import React, { Component } from "react";
import { Checkbox } from "pretty-checkbox-react";
export default class Pilot extends Component {
  make2(str) {
    return str.len === 1 ? "0" + str : str;
  }
  render() {
    return (
      <div className="pilot-form">
        <p className="pilot-nr">
          Pilot {this.make2(this.props.number)} (Check Starting Pilot for the
          pilot that will drive when race is starting)
        </p>
        <div className="pilot-form-container">
          <label htmlFor="tnumber" className="teamLabels">
            Team Number
          </label>
          <input
            type="text"
            id="teamNumber"
            placeholder="01"
            className="teamInput"
            value={this.props.teamNumber}
            onChange={(e) => {
              this.props.setTNumber(e.target.value);
            }}
          ></input>

          <label htmlFor="name" className="teamLabels">
            Pilot Name
          </label>
          <input
            type="text"
            id="pilotName"
            placeholder="Razvan Cristea"
            className="teamInput"
            value={this.props.name}
            onChange={(e) => this.props.setName(e.target.value)}
          ></input>

          <label htmlFor="name" className="teamLabels">
            Pilot Abbreviation
          </label>
          <input
            type="text"
            id="pilotAbbr"
            placeholder="RZV"
            className="teamInput"
            value={this.props.abbr}
            onChange={(e) => this.props.setAbbr(e.target.value)}
          ></input>

          <label htmlFor="name" className="teamLabels">
            Pilot Card ID
          </label>
          <input
            type="text"
            id="cardID"
            placeholder="1234567"
            className="teamInput"
            value={this.props.card}
            onChange={(e) => this.props.setCard(e.target.value)}
          ></input>

          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
            <label
              htmlFor="checkbox"
              className="teamLabels"
              style={{ flex: 1 }}
            >
              Starting Pilot
            </label>
            <Checkbox
              checked={this.props.isMain === 1 ? true : false}
              onChange={(e) => {
                this.props.setMain(e.target.checked ? 1 : 0);
              }}
              style={{ paddingLeft: 20, transform: "scale(2)" }}
              animation="pulse"
            ></Checkbox>
          </div>
        </div>
      </div>
    );
  }
}
