import React from "react";

import { Send } from "@mui/icons-material";
import Button from "@mui/material/Button";
import "./Styles/insertStartDate.css";
import axios from "axios";
import { api_credentials } from "../vars.js";
export default function InsertStartDate() {
  const [stime, setSTime] = React.useState();
  const [active, setActive] = React.useState(false);
  const [intervall, setIn] = React.useState(0);
  const [dates, setDates] = React.useState({
    startData: "",
    finishData: "",
  });
  let body = {
    key: api_credentials.key,
    api_user: api_credentials.user,
  };
  function setDate() {
    if (
      window.confirm(
        "are you sure, that you want to change the starting and finish date?"
      )
    ) {
      body = {
        start: dates.startData,
        end: dates.finishData,
        key: api_credentials.key,
        api_user: api_credentials.user,
      };
      axios
        .post(api_credentials.url + "/newdata", body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          window.alert(res.data.Data);
        });
    }
  }
  function serverTime() {
    if (!active) {
      let interval = setInterval(() => {
        axios
          .post(api_credentials.url + "/stime", body, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            setSTime(res.data.Data);
          });
      }, 1000);
      setIn(interval);
    } else {
      clearInterval(intervall);
      setSTime();
      setIn(0);
    }
    setActive(!active);
  }
  return (
    <div className="form-date">
      <label htmlFor="startDate" className="labelDate">
        Choose a start date(server time):<br></br>
        {stime}
      </label>
      <Button
        id={1}
        variant="contained"
        endIcon={<Send />}
        style={{
          width: "50%",
          marginBottom: 5,
        }}
        onClick={serverTime}
      >
        server time
      </Button>
      <input
        type="datetime-local"
        id="startDate"
        className="inputDate"
        value={dates.startData}
        onChange={(e) => {
          // console.log(e.target.value)
          setDates((prevObj) => {
            const newObj = { ...prevObj };
            newObj.startData = e.target.value;
            let finishData = new Date(e.target.value);
            finishData.setDate(finishData.getDate() + 1);
            finishData.setHours(finishData.getHours() + 3);
            newObj.finishData = finishData.toISOString().slice(0, 16);
            //    console.log(newObj)
            return newObj;
          });
        }}
      ></input>
      <label htmlFor="startDate" className="labelDate">
        Choose a finish date:
      </label>
      <input
        type="datetime-local"
        id="endDate"
        className="inputDate"
        value={dates.finishData}
        onChange={(e) => {
          // console.log(e.target.value)
          setDates((prevObj) => {
            const newObj = { ...prevObj };
            newObj.finishData = e.target.value;
            //    console.log(newObj)
            return newObj;
          });
        }}
      ></input>

      <Button
        id={2}
        variant="contained"
        endIcon={<Send />}
        style={{
          width: "50%",
          marginTop: 10,
        }}
        onClick={setDate}
      >
        apply
      </Button>
    </div>
  );
}
